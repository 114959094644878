<template>
  <div class="page_daInquiry flexCloumnSingleCenter">
    <div class="cell_group">
      <div class="cell_item">
        <div class="item_title">学号</div>
        <div class="item_value">
          <el-input v-model="query.stuNum" placeholder="学号"></el-input>

        </div>
      </div>
      <div class="cell_item">
        <div class="item_title">姓名</div>
        <div class="item_value">
          <el-input v-model="query.stuName" placeholder="姓名"></el-input>

        </div>
      </div>
      <div class="cell_item">
      <div class="queryBtn flexCenter" @click="getList">查询</div>
        </div>
    </div>

    <div v-if="info.mailing_num&& (query.stuNum==info.STU_NUM)&&(query.stuName==info.STU_NAME)" class="cell_group"
    style="margin-top: 20px;">
      <div class="cell_item">
        <div class="item_title">学号</div>
        <div class="item_value">{{ info.STU_NUM }}</div>
      </div>
      <div class="cell_item">
        <div class="item_title">姓名</div>
        <div class="item_value">{{ info.STU_NAME }}</div>
      </div>
      <div class="cell_item">
        <div class="item_title">收件人</div>
        <div class="item_value">{{ info.recipient }}</div>
      </div>

      <div class="cell_item">
        <div class="item_title">收件地址</div>
        <div class="item_value">{{ info.send_address }}</div>
      </div>
      <div class="cell_item">
        <div class="item_title">联系电话</div>
        <div class="item_value">{{ info.phone }}</div>
      </div>

      <div class="cell_item">
        <div class="item_title">EMS运单号</div>
        <div class="item_value flexStart">
          {{ info.mailing_num }}
          <img src="../../../../public/img/daInquiry_copy.png" alt="" style="width: 20px;height: 20px;margin-left: 10px;"
          @click="onCopy">
        </div>
      </div>
    </div>
    <div id="toast" class="toast">11</div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      DataList: [],
      joined: false,
      viewResult: false,
      expired: false, //是否过期
      info:{},
      query:{

      }
    }
  },
  mounted() {

  },
  methods: {
    onCopy(){
      var input = document.createElement("input"); // 创建input对象
      input.value = this.info.mailing_num; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功~");

    },
    getList(page) {
      if (!this.query.stuNum) {
        this.$message.warning("请输入学号~")
        return
      }
      if (!this.query.stuName) {
        this.$message.warning("请输入姓名~")
        return
      }
      const loading =this.$loading();

      let apiurl = "/api/xwzz_emsBdz_by_stuNum"
      if(this.$route.query.school=='xwzz'){
        apiurl = "/api/xwzz_emsBdz_by_stuNum"
      }
      console.log(222,apiurl)

      this.$http.post(apiurl, {
        stuNum: this.query.stuNum,
        stuName: this.query.stuName,
      }).then((res) => {
        loading.close();
        if (res.data && res.data.mailing_num) {
          this.info = res.data;
        } else {
          this.$message.error("抱歉，暂未查到运单号，请耐心等待~")
        }
      })
      setTimeout(function (){
        loading.close();
      },2000)
    },

    showToast(msg) {
      $("#toast").text(msg).show().animate({
        opacity: 1
      }, 200, "linear", function() {
        setTimeout(() => {
          $("#toast").animate({
            opacity: 0
          }, "linear", 3000, function() {
            $("#toast").hide()
          })
        }, 2000)
      })
    },
  }
}
</script>

<style scoped lang="less" type="text/less">
.page_daInquiry{
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #f2f4f8;
  box-sizing: border-box;
  div{box-sizing: border-box;}
  .cell_group{
    width: 800px;
    border-radius: 8px;
    box-shadow: 0 0 5px #ccc;
    background-color: #fff;
    padding: 0 20px;
    .cell_item{
      border-bottom: 1px solid #efefef;
      padding: 15px 0;
      .item_title{
        color: #333333;
        font-size: 14px;
      }
      .item_value{
        margin-top: 10px;
        //padding-left: 4px;
        color: #909399;
        font-size: 16px;
      }
    }
    .cell_item:last-of-type{
      border: 0;
    }
    .queryBtn{
      cursor: pointer;
      padding: 10px 0;
width: 100%;
      border-radius: 4px;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 700;
      background-color: #409eff;
    }
  }
}

.required {
  color: orangered;
}

.notrequired {
  color: #FFFFFF;
}

.toast {
  position: fixed;
  width: 70%;
  left: 15%;
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  bottom: 50%;
  background: orangered;
  color: #FFFFFF;
  font-size: 14px;
  opacity: 0;
  display: none;
}
</style>
